import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import Default from "@hoc/Default";
import Link from "@components/Link";
import Button from "@components/Button";
import { IConfig } from "gatsby-node";
const { formatSocials } = require("../utils");

interface INotFoundPageProps {
  data: {
    file: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    config: IConfig;
  };
}

const NotFoundPage: React.FC<INotFoundPageProps> = ({ data }) => {
  const backgroundImage = getImage(data.file.childImageSharp.gatsbyImageData);
  const bg = convertToBgImage(backgroundImage);
  const config = data.config;
  config.socials = formatSocials(config);
  return (
    <Default config={config} locale={"en"}>
      <BackgroundImage {...bg} Tag="section" className="">
        <div className="min-h-[75vh] bg-gradient-to-r from-darkJungleGreen/60 w-full flex items-center justify-center text-center md:text-left flex-col">
          <div className="w-full xl:container px-6 xl:px-10 mx-auto">
            <h1 className="mb-2 md:mb-6 text-3xl md:text-6xl lg:text-7xl font-display uppercase text-white">
              PAGE NOT FOUND
            </h1>

            <h2 className="mb-24 md:mb-16 text-2xl md:text-3xl lg:text-4xl font-display uppercase text-white">
              Vessel, you have lost your way.
            </h2>
            <Button
              {...{
                to: "/",
                color: "primary",
                link: true,
                external: false,
                className:
                  "overflow-hidden text-center group transition-all duration-500 relative flex items-center justify-center mt-6 w-fit mx-auto md:mx-0 px-10 py-3 decoration-none uppercase tracking-wider bg-black group-hover:text-black hover:text-black",
              }}
            >
              Back to home
            </Button>
          </div>
        </div>
      </BackgroundImage>
    </Default>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ErrorPageQuery {
    file(relativePath: { eq: "img-404-bkg.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    config: datoCmsGlobalSetting {
      brandingLogoStacked {
        gatsbyImageData(placeholder: BLURRED)
      }
      brandingLogoInline {
        gatsbyImageData(placeholder: BLURRED)
      }
      brandingStudioLogo {
        url
      }
      brandingStudioLogo2 {
        url
      }
      brandingRatingLogo {
        url
      }
      brandingRatingLogo2 {
        url
      }
      brandingSocialFrame {
        gatsbyImageData(placeholder: BLURRED)
      }
      headerNavigationLinks {
        locale
        external
        label
        url
        page {
          ... on DatoCmsPage {
            slug
            model {
              name
            }
          }
          ... on DatoCmsNews {
            slug
            model {
              name
            }
          }
        }
      }
      headerButtons {
        type: linkType
        label
        color: colour
        link {
          ... on DatoCmsPage {
            slug
            model {
              name
            }
          }
          ... on DatoCmsNews {
            slug
            model {
              name
            }
          }
        }
        url
      }
      footerNavigationLinks {
        locale
        external
        label
        url
        page {
          ... on DatoCmsPage {
            slug
            model {
              name
            }
          }
          ... on DatoCmsNews {
            slug
            model {
              name
            }
          }
        }
      }
      copyrightStatement

      youtubeIcon {
        url
      }
      youtubeLink

      instagramIcon {
        url
      }
      instagramLink

      twitterIcon {
        url
      }
      twitterLink

      discordIcon {
        url
      }
      discordLink
      steamLogo {
        url
      }
      newsletterBackground {
        gatsbyImageData(placeholder: BLURRED)
      }
      newsletterHeading
      newsletterBody
    }
  }
`;
